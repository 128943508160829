import { useEffect } from "react";

// import { Link } from "react-router-dom";
import Heading from "../components/Heading";
import AllElements from "../components/AllElements";
import Background from "../components/Background";
import ScrollKey from "../components/ScrollKey";
// import '../App.css';

const Home = () => {
    // let movingDiv = <div id="movingText"><p>This is helllo world from booknabe</p></div>;

    useEffect(() => {
        console.log(" ---- USEEFFECT has been triggered");

        const handleScroll = event => {
            console.log('window.scrollY', window.scrollY);
            //For fade in of heading(BOOKNABE) on initial scroll
            if (window.scrollY >= 0 && window.scrollY < 400) {
                let hop = 0 + (window.scrollY - 150) / 250;
                document.documentElement.style.setProperty('--opacityHeading', hop);
            }
            else if (window.scrollY >= 4614) {
                //for fade Out of heading
                let hop = 1 - (window.scrollY - 4614) / 400;
                document.documentElement.style.setProperty('--opacityHeading', hop);
            }
            else {
                document.documentElement.style.setProperty('--opacityHeading', 1);
            }

            //for movingText

            // if (window.scrollY >= 0 && window.scrollY < 270) {
            if (window.scrollY >= 0 && window.scrollY < 310) {

                // let moveBy = 500 - 3 * (window.scrollY);
                let moveBy = 100 - 3 * (window.scrollY);
                // console.log("moveBy is ", moveBy);
                document.documentElement.style.setProperty('--movingTextMoveBy', `${moveBy}px`);

            }

            //for opacity of moving text

            if (window.scrollY > 0 && window.scrollY <= 260) {
                document.documentElement.style.setProperty('--opacityMovingText', 1);
            }
            // else if (window.scrollY > 220) {
            //     let op = (270 - window.scrollY) / 70;
            //     document.documentElement.style.setProperty('--opacityMovingText', op);
            // }
            else if (window.scrollY > 260) {
                let op = (310 - window.scrollY) / 70;
                document.documentElement.style.setProperty('--opacityMovingText', op);
            }

            // For heading translation to left
            // let fixedValue = (1100/2720)*(window.innerWidth);
            // let fixedValue = (613/1540)*(window.innerWidth);
            // let fixedValue2 = (250/770)*(window.innerWidth);

            // if (window.scrollY >= 0 && window.scrollY < 374) {
            //     // let efg = 0 - (635/374)*(window.scrollY);
            //     let efg = 0 - (fixedValue/374)*(window.scrollY);
            //     let efg2 = 0 - (fixedValue/374)*0.8*(window.scrollY);
            //     document.documentElement.style.setProperty('--headingX', `${efg}px`);
            //     document.documentElement.style.setProperty('--headingXForMobile', `${efg2}px`);

            // }

            // else if (window.scrollY >= 374) {
            //     // document.documentElement.style.setProperty('--headingX', `-635px`);
            //     document.documentElement.style.setProperty('--headingX', `-${fixedValue}px`);
            //     document.documentElement.style.setProperty('--headingXForMobile', `-${fixedValue2}px`);
            // }





            //for zoom out of initial mobile screen in  ---MOBILE---
            if (window.scrollY >= 0 && window.scrollY < 300) {
                let abc = 5 - 1 * (window.scrollY) * 4 / 300;
                document.documentElement.style.setProperty('--zoomScale', abc);
                let pqrst = 0.3 + (window.scrollY) * 0.3 / 300;
                document.documentElement.style.setProperty('--opacityOfImage1', pqrst);
            }
            else if (window.scrollY > 300) {
                document.documentElement.style.setProperty('--zoomScale', 1);
                document.documentElement.style.setProperty('--opacityOfImage1', 1);
            }
            // For 393 px
            if (window.scrollY >= 0 && window.scrollY < 600) {
                let abc = 5 - 1 * (window.scrollY) * 4 / 600;
                document.documentElement.style.setProperty('--zoomScaleMobile', abc);
            }
            else if (window.scrollY > 300) {
                document.documentElement.style.setProperty('--zoomScaleMobile', 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
    }, []);
    const scc = ScrollKey();
    // let movingDiv = <div id="movingText"><p>This is helllo world from booknabe</p></div>;
    // if (scc >= 0 && scc < 200) {
    //     movingDiv = <div id="movingText"><p>This is helllo world from booknabe</p></div>;
    // } else movingDiv = <div></div>;

    
    return (
        <>
            <div className="homePage homePage-bg">
                <Heading />

                {/* <div id="movingText"><p>This is helllo world from booknabe</p></div> */}
                {/* {(scc >= 0 && scc < 200) && <div id="movingText"><p>  Books by you, For you </p></div>} */}
                {(scc >= 0 && scc < 500) && <div id="movingText"><p>  One Stop Solution to exchange, buy or sell books around neighborhood </p></div>}

                <AllElements />
                <Background />
            </div>

        </>
    );
}

export default Home;