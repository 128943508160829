import React from 'react'
import img from '../images/logobn1.png' 
export const Loader = () => {
    return (
        <div id="myContainer">
            <img src={img} className="loader-logo" alt="logo" />
            <div id="flip">
                <div><div>Exchange</div></div>
                <div><div>Sell</div></div>
                <div><div>Buy</div></div>
            </div>
            Repeat
        </div>
    )
}
