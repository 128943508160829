import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import logo from "../images/bookbg.jpg";
import logo from "../images/logobn.png";
import { Loader } from "./Loader";
import './card.scss';


const Card = (props) => {
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      axios('https://booknabe-sell-testing.herokuapp.com/api/tnc-privacy')
        .then((response) => {
          setPolicy(response.data);
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, []);

  return (
    loading ? <Loader /> :
      <div class="card">
        <div class="card-image">
          <Link to="/"><img src={logo} alt="BookNabe-logo" /></Link>
        </div>
        <div class="card-body">
          {/* <div class="card-date">
          <time>20 Novembre 1992</time>
        </div> */}

          <div class="card-title">
            <h2>{(props.content === "tnc") ? "Terms and Conditions" : "Privacy Policy"}</h2>

            {/* <h3>Title</h3> */}

          </div>

          <div class="card-excerpt">
            {/* ReactDOM.render({policy.content.tnc}) */}
            <div className="content" dangerouslySetInnerHTML={{ __html: (props.content === "tnc") ? (policy.content.tnc) : (policy.content.privacy) }}></div>
            {/* <div className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(policy.content.tnc) }}></div> */}
            {/* <p>{policy.content.tnc}</p> */}
            {/* <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, culpa saepe a </p> */}
          </div>

        </div>

      </div>
  )
}

export default Card;