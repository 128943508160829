import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Card from "./components/Card";
import { Loader } from "./components/Loader";
import About from "./pages/About";
// import './App.css';

function App() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2500)
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={loading ? <Loader /> : <Home />} />
                <Route path="/privacy" element={<Card content="privacy" />} />
                <Route path="/tnc" element={<Card content="tnc" />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;