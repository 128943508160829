import React from "react";
import BgContent from "./BgContent";
import { Books } from "./Books";
import Footer from "./Footer";

const AllElements = () => {
    return (
        <div className="allElements">
            <BgContent />
            <Books/>
            <Footer />
        </div>

    );
}

export default AllElements;