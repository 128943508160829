import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logobn.png";
// import img1 from "../../images/img1.jpg";
// import vision from "../../images/vision.jpg";
// import phoneUsing from "../../images/phoneUsing.jpg";
// import flyingDude from "../../images/flyingDude.svg";

import teamwork from "../../images/teamwork.jpg";
import idea from "../../images/idea.jpg";
import thinking from "../../images/thinking.jpg";
import plan from "../../images/plan.jpg";
import Footer from "../Footer";

import './VisionAndMission.scss';

const VisionAndMission = () => {
    return (

        <div className="visionAndMission">
            <header className="header">
                <img src={logo} alt="logo" className="logo" />
                <nav className="navbar">
                    <ul className="navbar-lists">
                        <li><Link to="/" className="navbar-link ">Home</Link></li>
                        <li><Link to="/about" className="navbar-link ">About</Link></li>
                    </ul>
                </nav>
            </header>
            <main className="mainVM">
                <section className="section-hero section">
                    <div className="hero-container grid grid-two-column">
                        <div className="section-hero-data hero-data-left align-right">
                            <div className="hero-data-container">

                                <p className="hero-top-data">
                                    This is me
                                </p>
                                <h1 className="hero-heading">Our Team</h1>
                                <p className="hero-para"> Description of the compnay used by the company.</p>

                            </div>
                        </div>
                        <div className="section-hero-image">
                            <img src={teamwork} alt="teamwork" className="hero-img" />
                        </div>
                    </div>
                </section>
                <section className="section-hero section">
                    <div className="hero-container grid grid-two-column reverse">
                        <div className="section-hero-image">
                            <img src={idea} alt="idea" className="hero-img" />
                        </div>
                        <div className="section-hero-data align-left">
                            <div className="hero-data-container ">
                                <p className="hero-top-data">
                                    This is me
                                </p>
                                <h1 className="hero-heading">Our Vision</h1>
                                <p className="hero-para"> Description of the compnay used by the company.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-hero section">
                    <div className="hero-container grid grid-two-column">
                        <div className="section-hero-data hero-data-left align-right">
                            <div className="hero-data-container">
                                <p className="hero-top-data">
                                    This is me
                                </p>
                                <h1 className="hero-heading">Our mission</h1>
                                <p className="hero-para"> Description of the compnay used by the company.</p>
                            </div>
                        </div>
                        <div className="section-hero-image">
                            <img src={thinking} alt="thinking" className="hero-img" />
                        </div>
                    </div>
                </section>
                <section className="section-hero section">
                    <div className="hero-container grid grid-two-column reverse">
                        <div className="section-hero-image">
                            <img src={plan} alt="plan" className="hero-img" />
                        </div>
                        <div className="section-hero-data align-left">
                            <div className="hero-data-container ">
                                <p className="hero-top-data">
                                    This is me
                                </p>
                                <h1 className="hero-heading">Future Plans</h1>
                                <p className="hero-para"> Description of the compnay used by the company.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div >

    );
}

export default VisionAndMission;