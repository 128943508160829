import React from 'react'
import './books.scss'

export const Books = () => {
  return (
<>
<div class="shelf">
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>

  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
  <div class="book"></div>
</div>
</>
  )
}
