import { useEffect, useRef } from "react";
// import ScrollKey from "./ScrollKey";
// import Image from "./Image";
// import i1 from '../images/forWeb2.jpg';
// import i2 from '../images/i2.jpg';
// import i3 from '../images/i3.jpg';
// import i4 from '../images/i4.jpg';
// import i5 from '../images/i1.jpg';
// import i6 from '../images/i11.jpg';
import i1 from '../images/ii1.webp';
import i2 from '../images/ii2.webp';
import i3 from '../images/ii3.webp';
// import i4 from '../images/ii4.gif';
import i4 from '../images/ii4.webp';
import i5 from '../images/ii5.webp';
import i6 from '../images/ii6.webp';

const Frame = () => {

    const ref1 = useRef(null);

    useEffect(() => {
        // const mTopOfImage1 = getComputedStyle(document.documentElement).getPropertyValue('--pink');
        // const mLeftOfImage2 = getComputedStyle(document.documentElement).getPropertyValue('--pink2');
        // const mLeftOfImage3 = getComputedStyle(document.documentElement).getPropertyValue('--pink3');
        // const mLeftOfImage4 = getComputedStyle(document.documentElement).getPropertyValue('--pink4');
        const handleScroll = event => {

            // if (window.innerWidth > 530 && window.innerWidth < 769) {
            //     let imageWidth = 300;
            //     document.documentElement.style.setProperty('--imageWidth', `${imageWidth}px`);
            // }
            console.log('window.scrollY', window.scrollY);
            let wlaptop = ref1.current.clientWidth;
            let containerWidthLaptop = ref1.current.clientWidth + 44;
            document.documentElement.style.setProperty('--containerWidthLaptop', `${containerWidthLaptop}px`);
            document.documentElement.style.setProperty('--imageWidth', `${wlaptop}px`);
            let paddingOfContainer = (containerWidthLaptop - wlaptop)/2;
            document.documentElement.style.setProperty('--paddingOfContainer', `${paddingOfContainer}px`);

            // For image 1
            if (window.scrollY >= 0 && window.scrollY < 55) {
                let abc = 33 - 0.6 * window.scrollY;
                document.documentElement.style.setProperty('--mTopOfImage1', `${abc}px`);
            }
            else if (window.scrollY >= 55 && window.scrollY < 1045) {
                document.documentElement.style.setProperty('--mTopOfImage1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            // For image 2
            else if (window.scrollY >= 1045 && window.scrollY < (1045+wlaptop)) {
                let abc2 = 1045 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage2', `${abc2}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            else if (window.scrollY >= (1045+wlaptop) && window.scrollY < 1780) {
                document.documentElement.style.setProperty('--mLeftOfImage2', `-${wlaptop}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            // For image 3
            else if (window.scrollY >= 1780 && window.scrollY < (1780+wlaptop)) {
                let abc3 = 1780 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage3', `${abc3}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            else if (window.scrollY >= (1780+wlaptop) && window.scrollY < 2515) {
                document.documentElement.style.setProperty('--mLeftOfImage3', `-${wlaptop}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            // For image 4
            else if (window.scrollY >= 2515 && window.scrollY < (2515+wlaptop)) {
                let abc4 = 2515 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage4', `${abc4}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            else if (window.scrollY >= (2515+wlaptop) && window.scrollY < 3250) {
                document.documentElement.style.setProperty('--mLeftOfImage4', `-${wlaptop}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            // For image 5
            else if (window.scrollY >= 3250 && window.scrollY < (3250+wlaptop)) {
                let abc5 = 3250 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage5', `${abc5}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            else if (window.scrollY >= (3250+wlaptop) && window.scrollY < 3985) {
                document.documentElement.style.setProperty('--mLeftOfImage5', `-${wlaptop}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6', `0px`);
            }
            // For image 6
            else if (window.scrollY >= 3985 && window.scrollY < (3985+wlaptop)) {
                let abc6 = 3985 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage6', `${abc6}px`);
            }
            else if (window.scrollY >= (3985+wlaptop)) {
                document.documentElement.style.setProperty('--mLeftOfImage6', `-${wlaptop}px`);
            }


            // F O R   M O B I L E   V E R S I O N -  ( 500 px X 720 px )

            // For image 1 m
            if (window.scrollY >= 0 && window.scrollY < 50) {
                let abcm = 50 - window.scrollY;
                // console.log("abcm is ", abcm);
                document.documentElement.style.setProperty('--mTopOfImage1Mobile', `${abcm}px`);
            }
            else if (window.scrollY >= 50 && window.scrollY < 1045) {
                document.documentElement.style.setProperty('--mTopOfImage1Mobile', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage2Mobile', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage3Mobile', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `0px`);
            }
            // For image 2 m
            if (window.scrollY >= 1045 && window.scrollY < 1172) {
                let abc2m = 1045 - window.scrollY;
                // console.log("abc2 is ", abc2m);
                document.documentElement.style.setProperty('--mLeftOfImage2Mobile', `${abc2m}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3Mobile', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `0px`);
            }
            else if (window.scrollY >= 1172 && window.scrollY < 1780) {
                document.documentElement.style.setProperty('--mLeftOfImage2Mobile', `-233px`);
                document.documentElement.style.setProperty('--mLeftOfImage3Mobile', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `0px`);
            }
            // For image 3 m
            else if (window.scrollY >= 1780 && window.scrollY < 1898) {
                let abc3m = 1780 - window.scrollY;
                // console.log("abcForPink3  is ", abc3m);
                document.documentElement.style.setProperty('--mLeftOfImage3Mobile', `${abc3m}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `0px`);
            }
            else if (window.scrollY >= 1898 && window.scrollY < 2515) {
                document.documentElement.style.setProperty('--mLeftOfImage3Mobile', `-233px`);
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `0px`);
            }
            // For image 4 m
            else if (window.scrollY >= 2515 && window.scrollY < 2630) {
                let abc4m = 2515 - window.scrollY;
                // console.log("abcForPink4  is ", abc4m);
                // if (abc4m > -327 && abc4m < 0)
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `${abc4m}px`);
            }
            else if (window.scrollY >= 2630) {
                document.documentElement.style.setProperty('--mLeftOfImage4Mobile', `-233px`);
            }

            // F O R   M O B I L E   V E R S I O N -  ( 394 px X 844 px )

            // For image 1 m

            if (window.scrollY >= 0 && window.scrollY < 55) {
                let abcmi = 34 - window.scrollY;

                document.documentElement.style.setProperty('--mTopOfImage1MobileV1', `${abcmi}px`);
            }
            else if (window.scrollY >= 50 && window.scrollY < 1045) {
                document.documentElement.style.setProperty('--mTopOfImage1MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            // For image 2 m
            if (window.scrollY >= 1045 && window.scrollY < 1218) {
                let abc2mi = 1045 - window.scrollY;

                document.documentElement.style.setProperty('--mLeftOfImage2MobileV1', `${abc2mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            else if (window.scrollY >= 1218 && window.scrollY < 1620) {
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV1', `-279px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            // For image 3 m

            else if (window.scrollY >= 1620 && window.scrollY < 1899) {
                let abc3mi = 1620 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV1', `${abc3mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            else if (window.scrollY >= 1899 && window.scrollY < 2300) {
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV1', `-279px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            // For image 4 m

            else if (window.scrollY >= 2300 && window.scrollY < 2579) {
                let abc4mi = 2300 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `${abc4mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            else if (window.scrollY >= 2579 && window.scrollY < 2940) {
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV1', `-279px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            // For image 5 m
            else if (window.scrollY >= 2940 && window.scrollY < 3220) {
                let abc5mi = 2940 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `${abc5mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            else if (window.scrollY >= 3220 && window.scrollY < 3620) {
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV1', `-282px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `0px`);
            }
            // For image 6 m
            else if (window.scrollY >= 3620 && window.scrollY < 3900) {
                let abc6mi = 3620 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `${abc6mi}px`);
            }
            else if (window.scrollY >= 3900) {
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV1', `-282px`);
            }
            // F O R   M O B I L E   V E R S I O N -  ( 375 px X 667 px )

            // For image 1 m
            if (window.scrollY >= 0 && window.scrollY < 50) {
                let abcmi = 50 - window.scrollY;
                document.documentElement.style.setProperty('--mTopOfImage1MobileV2', `${abcmi}px`);

            }
            else if (window.scrollY >= 50 && window.scrollY < 1045) {
                document.documentElement.style.setProperty('--mTopOfImage1MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            // For image 2 m
            if (window.scrollY >= 1045 && window.scrollY < 1159) {
                let abc2mi = 1045 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV2', `${abc2mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            else if (window.scrollY >= 1159 && window.scrollY < 1780) {
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV2', `-220px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            // For image 3 m
            else if (window.scrollY >= 1780 && window.scrollY < 1894) {
                let abc3mi = 1780 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV2', `${abc3mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            else if (window.scrollY >= 1894 && window.scrollY < 2515) {
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV2', `-220px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            // For image 4 m
            else if (window.scrollY >= 2515 && window.scrollY < 2629) {
                let abc4mi = 2515 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `${abc4mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            else if (window.scrollY >= 2629 && window.scrollY < 3250) {
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV2', `-220px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            // For image 5 m
            else if (window.scrollY >= 3250 && window.scrollY < 3364) {
                let abc5mi = 3250 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `${abc5mi}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            else if (window.scrollY >= 3364 && window.scrollY < 3985) {
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV2', `-220px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `0px`);
            }
            // For image 6 m
            else if (window.scrollY >= 3985 && window.scrollY < 4099) {
                let abc6mi = 3985 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `${abc6mi}px`);
            }
            else if (window.scrollY >= 4099) {
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV2', `-220px`);
            }
            // F O R   M O B I L E   V E R S I O N -  ( 360 px X 740 px )

            let w123456 = ref1.current.clientWidth;
            let containerHeight = ref1.current.clientHeight + 37;
            document.documentElement.style.setProperty('--containerHeight', `${containerHeight}px`);
            let containerWidth = ref1.current.clientWidth + 30;
            document.documentElement.style.setProperty('--containerWidth', `${containerWidth}px`);

            // For image 1 m
            if (window.scrollY >= 0 && window.scrollY < 50) {
                let abcmv3 = 50 - window.scrollY;
                document.documentElement.style.setProperty('--mTopOfImage1MobileV3', `${abcmv3}px`);
            }
            else if (window.scrollY >= 50 && window.scrollY < 1045) {
                document.documentElement.style.setProperty('--mTopOfImage1MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            // For image 2 m
            if (window.scrollY >= 1045 && window.scrollY < (1045 + w123456)) {
                let abc2mv3 = 1045 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV3', `${abc2mv3}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            else if (window.scrollY >= 1183 && window.scrollY < 1780) {
                document.documentElement.style.setProperty('--mLeftOfImage2MobileV3', `-${w123456}px`);
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            // For image 3 m
            else if (window.scrollY >= 1780 && window.scrollY < (1780 + w123456)) {
                let abc3mv3 = 1780 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV3', `${abc3mv3}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            else if (window.scrollY >= 1918 && window.scrollY < 2515) {
                document.documentElement.style.setProperty('--mLeftOfImage3MobileV3', `-${w123456}px`);
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            // For image 4 m
            else if (window.scrollY >= 2515 && window.scrollY < (2515 + w123456)) {
                let abc4mv3 = 2515 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `${abc4mv3}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            else if (window.scrollY >= 2653 && window.scrollY < 3250) {
                document.documentElement.style.setProperty('--mLeftOfImage4MobileV3', `-${w123456}px`);
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `0px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            // For image 5 m
            else if (window.scrollY >= 3250 && window.scrollY < (3250 + w123456)) {
                let abc5mv3 = 3250 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `${abc5mv3}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            else if (window.scrollY >= 3388 && window.scrollY < 3985) {
                document.documentElement.style.setProperty('--mLeftOfImage5MobileV3', `-${w123456}px`);
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `0px`);
            }
            // For image 6 m
            else if (window.scrollY >= 3985 && window.scrollY < (3985 + w123456)) {
                let abc6mv3 = 3985 - window.scrollY;
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `${abc6mv3}px`);
            }
            else if (window.scrollY >= 4123) {
                document.documentElement.style.setProperty('--mLeftOfImage6MobileV3', `-${w123456}px`);
            }



        };

        window.addEventListener('scroll', handleScroll);

    }, []);

    return (
        <div className="container">
            <div className="displayContent" >
                <img src={i1} alt="i1" id="i1" ref={ref1} />
                <img src={i2} alt="i2" id="i2" />
                <img src={i3} alt="i3" id="i3" />
                <img src={i4} alt="i4" id="i4" />
                <img src={i5} alt="i4" id="i5" />
                <img src={i6} alt="i4" id="i6" />
                {/* <img src={i1} alt="i1" id="i1" ref={ref1} />
                <img src={i2} alt="i2" id="i2" ref={ref2} />
                <img src={i3} alt="i3" id="i3" ref={ref3} />
                <img src={i4} alt="i4" id="i4" ref={ref4} />
                <img src={i5} alt="i4" id="i5" ref={ref5} />
                <img src={i6} alt="i4" id="i6" ref={ref6} /> */}


                {/* <Image alternate="i1" source={i1} />
                <Image alternate="i2" source={i2} />
                <Image alternate="i3" source={i3} />
                <Image alternate="i4" source={i4} /> */}

            </div>
        </div>
    );
}

export default Frame;