import React from "react";
// import logo2 from "../images/logobn.png";


function Heading() {
    return (
        <div className="heading">
            <div className="logo-image">
            {/* <img src={logo2} alt="footer-logo.png" /> */}


                <h1 className="logo-text heading-color">BOOK
                NABE</h1>
            </div>
        </div>
    );
}

export default Heading;