import React from "react";
import { useEffect } from "react";
// import bgContentHeight  from "./BgContent";
// import footerHeight from "./Footer";

const Background = () => {
    useEffect(() => {

        let footerHeight = document.getElementById("footerId").offsetHeight;
        console.log("Footer height is:", footerHeight);
        let bgContentHeight = document.getElementById("bgContentId").offsetHeight;
        console.log("Height of bgcontent is:", bgContentHeight);
        // let bkgHeight = 55 + 6 * window.screen.height + footerHeight ;
        // let bkgHeight = 4720 + footerHeight + window.screen.height;
        let bkgHeight = 4720 + footerHeight + bgContentHeight;
        let bkgHeight2 = 4720 + footerHeight + bgContentHeight;
        let bkgHeight3 = 4720 + footerHeight + bgContentHeight;
        let bkgHeight4 = 4720 + footerHeight + bgContentHeight;
        let bkgHeight5 = 4720 + footerHeight + bgContentHeight;
        document.documentElement.style.setProperty('--bkgHeight', `${bkgHeight}px`);
        document.documentElement.style.setProperty('--bkgHeight2', `${bkgHeight2}px`);
        document.documentElement.style.setProperty('--bkgHeight3', `${bkgHeight3}px`);
        document.documentElement.style.setProperty('--bkgHeight4', `${bkgHeight4}px`);
        document.documentElement.style.setProperty('--bkgHeight5', `${bkgHeight5}px`);
    },[])

    return (
        <>
            <div id="bkg"></div>
        </>
    );
}

export default Background;