import { useEffect } from "react";
import ScrollKey from "./ScrollKey";
// import LibImage from "./LibImage";
import Frame from "./MobileFrame";


// let bgContentHeight;
const BgContent = () => {
    // const refBgContent = useRef(null);
    // useEffect(() => {
    //     function heightOfBgContent() {
    //         let bgContentHeight = refBgContent.current.clientHeight;
    //         return bgContentHeight;
    //     }
    // }, []);
    // function getImageIndex(y) {
    //     return (y - 55) / 724;
    // }
    useEffect(() => {
        // const p1 = getComputedStyle(document.documentElement).getPropertyValue('--p1');
        // const p2 = getComputedStyle(document.documentElement).getPropertyValue('--p2');
        // const p3 = getComputedStyle(document.documentElement).getPropertyValue('--p3');
        // const positionOfBgContent = getComputedStyle(document.documentElement).getPropertyValue('--positionOfBgContent');
        // const mtBgContent = getComputedStyle(document.documentElement).getPropertyValue('--mtBgContent');

        const handleScroll = event => {
            // For laptops : --movePara1, --movePara2, --movePara3, --movePara4
            // if (window.scrollY >= 204) {
            //     let xyz = 407 - 1.3 * (window.scrollY - (204 + (getImageIndex(window.scrollY) * 735)));
            //     document.documentElement.style.setProperty('--movePara1', `${xyz}px`);
            // }


            // For laptops : --movePara1, --movePara2, --movePara3, --movePara4
            if (window.scrollY >= 310 && window.scrollY < 1045) {
                let xyz = 407 - 1.3 * (window.scrollY - 310);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz = 407 - 1 * (window.scrollY - 310);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz = 407 - 1.1 * (window.scrollY - 310);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz = 407 - 1.2 * (window.scrollY - 310);
                }
                // console.log("xyz is ", xyz);
                document.documentElement.style.setProperty('--movePara1', `${xyz}px`);

            }
            else if (window.scrollY >= 1045 && window.scrollY < 1780) {
                let xyz2 = 407 - 1.3 * (window.scrollY - 1045);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz2 = 407 - 1 * (window.scrollY - 1045);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz2 = 407 - 1.1 * (window.scrollY - 1045);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz2 = 407 - 1.2 * (window.scrollY - 1045);
                }
                // console.log("xyz2 is ", xyz2);
                document.documentElement.style.setProperty('--movePara2', `${xyz2}px`);

            }
            else if (window.scrollY >= 1780 && window.scrollY < 2515) {
                let xyz3 = 407 - 1.3 * (window.scrollY - 1780);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz3 = 407 - 1 * (window.scrollY - 1780);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz3 = 407 - 1.1 * (window.scrollY - 1780);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz3 = 407 - 1.2 * (window.scrollY - 1780);
                }
                // console.log("xyz3 is ", xyz3);
                document.documentElement.style.setProperty('--movePara3', `${xyz3}px`);

            }
            else if (window.scrollY >= 2515 && window.scrollY < 3250) {
                let xyz4 = 407 - 1.3 * (window.scrollY - 2515);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz4 = 407 - 1 * (window.scrollY - 2515);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz4 = 407 - 1.1 * (window.scrollY - 2515);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz4 = 407 - 1.2 * (window.scrollY - 2515);
                }
                // console.log("xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--movePara4', `${xyz4}px`);

            }
            else if (window.scrollY >= 3250 && window.scrollY < 3985) {
                let xyz4 = 407 - 1.3 * (window.scrollY - 3250);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz4 = 407 - 1 * (window.scrollY - 3250);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz4 = 407 - 1.1 * (window.scrollY - 3250);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz4 = 407 - 1.2 * (window.scrollY - 3250);
                }
                // console.log("xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--movePara5', `${xyz4}px`);

            }
            else if (window.scrollY >= 3985 && window.scrollY < 4720) {
                let xyz4 = 407 - 1.3 * (window.scrollY - 3985);
                if (window.innerWidth >= 1024 && window.innerWidth < 1220) {
                    xyz4 = 407 - 1 * (window.scrollY - 3985);
                }
                else if (window.innerWidth >= 1220 && window.innerWidth < 1340) {
                    xyz4 = 407 - 1.1 * (window.scrollY - 3985);
                }
                else if (window.innerWidth >= 1340 && window.innerWidth < 1450) {
                    xyz4 = 407 - 1.2 * (window.scrollY - 3985);
                }
                // console.log("xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--movePara6', `${xyz4}px`);

            }

            // For mobile : --moveParaMobile1, --moveParaMobile2, --moveParaMobile3, --moveParaMobile4
            if (window.scrollY >= 470 && window.scrollY < 1080) {
                let xyz = 1000 - 2 * (window.scrollY - 470);

                // console.log(" for mobile xyz is ", xyz);
                document.documentElement.style.setProperty('--moveParaMobile1', `${xyz}px`);

            }
            else if (window.scrollY >= 1100 && window.scrollY < 1820) {
                let xyz2 = 1000 - 2 * (window.scrollY - 1100);
                // console.log("for mobile xyz2 is ", xyz2);
                document.documentElement.style.setProperty('--moveParaMobile2', `${xyz2}px`);

            }
            else if (window.scrollY >= 1820 && window.scrollY < 2400) {
                let xyz3 = 1000 - 2 * (window.scrollY - 1760);


                // console.log("for mobile xyz3 is ", xyz3);
                document.documentElement.style.setProperty('--moveParaMobile3', `${xyz3}px`);

            }
            else if (window.scrollY >= 2440 && window.scrollY < 3060) {
                let xyz4 = 1000 - 2 * (window.scrollY - 2440);

                // console.log("for mobile xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--moveParaMobile4', `${xyz4}px`);
            }
            else if (window.scrollY >= 3100 && window.scrollY < 3800) {
                let xyz5 = 1000 - 2 * (window.scrollY - 3100);

                // console.log("for mobile xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--moveParaMobile5', `${xyz5}px`);
            }
            else if (window.scrollY >= 3800 && window.scrollY < 4814) {
                let xyz6 = 1000 - 1.5 * (window.scrollY - 3800);

                // console.log("for mobile xyz4 is ", xyz4);
                document.documentElement.style.setProperty('--moveParaMobile6', `${xyz6}px`);
            }


            // For making bgContent scroll up on approaching Footer
            if (window.scrollY >= 0 && window.scrollY < 4720) {
                document.documentElement.style.setProperty('--positionOfBgContent', `fixed`);
                document.documentElement.style.setProperty('--mtBgContent', `0px`);
            }
            // else if (window.scrollY >= 4720) {
            //     let ijk = (6120 - window.scrollY) / ((6120 - 4720) / 500) - 500;
            //     console.log("Margin top of bg content is:", ijk);
            //     document.documentElement.style.setProperty('--mtBgContent', `${ijk}px`);
            // }
            else if (window.scrollY >= 4720) {
                // let ijk = (5595 - window.scrollY) / ((5595 - 4720) / 875) - 875;
                let ijk = 4720 - window.scrollY;
                console.log("Margin top of bg content is:", ijk);
                document.documentElement.style.setProperty('--mtBgContent', `${ijk}px`);
            }
        };

        window.addEventListener('scroll', handleScroll);
    }, []);




    let scc = ScrollKey();
    // const dataObj = [{ text: "lorem" }]
    const text1 = <p> <p className="headingOfContentText">Read and Grow!</p> We are unique as we make sure you not only read and exchange but build meaningful connections around you and with people of similar interest.</p>
    const text2 = <p> <p className="headingOfContentText">Pass it on!</p>  We might have just read a novel or completed an competitive exam, why not pass it around, to help other gain knowledge. Selling around can help anyone save a lot on budget yet receive the same knowledge or enter into their fiction land. Also, its a ZERO percent commission platform!</p>
    const text3 = <p> <p className="headingOfContentText">Go Specific!</p>  Know exactly what you want, explore page will exactly serve that purpose. Now find your favorite genre and see what books are around for you to exchange/buy. Build a community of like minded people with your interest areas!</p>
    const text4 = <p> <p className="headingOfContentText">Upload your collection!</p>  The magic plus (+) helps you upload any physical book that you have. Segment it out into Academics and Non-Academics and let our auto suggest feature help you find the book. Choose your action, upload the image of existing book and Vola! Your book is not visible to all.</p>
    const text5 = <p> <p className="headingOfContentText">Notifications and more!</p>  Know who all requested your books and the books that you requested for. It helps understand the status of the books and also make sure we have an idea about which of our book is in demand!</p>
    const text6 = <p> <p className="headingOfContentText">All about you and your books!</p>  View all the books you have uploaded and easily edit/delete. Let the dashboard keep you updated with information on exchanges/sell and bought. Feel free to change the Profile Picture to build trust!</p>

    return (
        <div className="bgContent" id="bgContentId">
            <div className="mobile-p">
                {(scc > 470 && scc < 1080) && <div id="pm1" className="mobile-content-p"> <p className="target-mobile">{text1}</p> </div>}
                {(scc > 1100 && scc < 1820) && <div id="pm2" className="mobile-content-p"><p className="target-mobile">{text2}</p> </div>}
                {(scc > 1820 && scc < 2400) && <div id="pm3" className="mobile-content-p"><p className="target-mobile">{text3}</p> </div>}
                {(scc > 2440 && scc < 3060) && <div id="pm4" className="mobile-content-p"><p className="target-mobile">{text4}</p> </div>}
                {(scc > 3100 && scc < 3800) && <div id="pm5" className="mobile-content-p"><p className="target-mobile">{text5}</p></div>}
                {(scc > 3800 && scc < 4814) && <div id="pm6" className="mobile-content-p"><p className="target-mobile">{text6}</p></div>}
            </div>

            <div className="content-title">
                {/* {(scc > 270 && scc < 1045) && <h1>Exchange</h1>} */}
                {(scc > 310 && scc < 1045) && <h1>Exchange</h1>}
                {(scc > 1045 && scc < 1780) && <h1>Buy/Sell</h1>}
                {(scc > 1780 && scc < 2515) && <h1>Explore</h1>}
                {(scc > 2515 && scc < 3250) && <h1>Add a Book</h1>}
                {(scc > 3250 && scc < 3985) && <h1>Request</h1>}
                {(scc > 3985 && scc < 4720) && <h1>Profile</h1>}
            </div>

            <div>
                <Frame />
            </div>

            <div className="content-p">
                {/* {(window.scrollY > 270) && <p id="p1" className="target">{dataObj[getImageIndex(window.scrollY)].text}</p>} */}

                {/* {(scc > 270 && scc < 1045) && <p id="p1" className="target">{text1}</p>} */}
                {(scc > 310 && scc < 1045) && <p id="p1" className="target">{text1}</p>}
                {(scc > 1045 && scc < 1780) && <p id="p2" className="target">{text2}</p>}
                {(scc > 1780 && scc < 2515) && <p id="p3" className="target">{text3}</p>}
                {(scc > 2515 && scc < 3250) && <p id="p4" className="target">{text4}</p>}
                {(scc > 3250 && scc < 3985) && <p id="p5" className="target">{text5}</p>}
                {(scc > 3985 && scc < 4720) && <p id="p6" className="target">{text6}</p>}
            </div>

        </div>
    );
}

// export default {BgContent, bgContentHeight};
export default BgContent;