import React from "react";
import VisionAndMission from "../components/VisonAndMission/VisionAndMission";

const About = () => {
    
    return (
        <>
        <VisionAndMission />
        </>
    );
}

export default About;